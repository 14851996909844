import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "stase";
const modelPath = `/${modelName}/`;

class Stase extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        mata_kuliah: {id: null, nama: ""},
        mulai: null,
        hingga: null,
        preceptor: {id: null, nama: ""},
        stasepspd_set: [],
        stasepengampu_set: [],
        dokumennilai_set: [],
        dokumennilaiakhir_set: [],
        template: null
    };
    let requiredFields = ["mulai", "hingga"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getPayload() {
    let data = super.getPayload();
    delete data["mata_kuliah"];
    delete data["stasepspd_set"];
    delete data["stasepengampu_set"];
    return data;
  }
}

export default Stase;