<template>
  <div class="akt-detail">
    <div class="columns no-margin">
      <div class="column no-padding is-full">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon
                class="has-text-light"
                size="is-small"
                icon="arrow-left"
              />
            </div>
            <p class="card-header-title has-text-light">ROTASI</p>
            <template v-if="canChange">
              <router-link
                :to="{ name: 'stase-change', params: { id: stase.id } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="edit" />
              </router-link>
              <a class="card-header-icon" @click.stop.prevent="confirmDelete">
                <b-icon
                  icon="trash"
                  size="is-small"
                  class="has-text-light"
                ></b-icon>
              </a>
            </template>
          </header>
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">
                Angkatan
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.angkatan }}
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">
                Mata Kuliah
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.mata_kuliah.nama }}
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">
                Periode
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.mulai }} - {{ stase.hingga }}
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">
                Penanggung Jawab
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <template v-if="stase.preceptor">{{
                  stase.preceptor.nama
                }}</template>
              </div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>

    <div class="columns pengampu-container">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Dosen Pengampu</p>
            <router-link
              v-if="isAdmin"
              :to="{
                name: 'stase-pengampu',
                params: { id: $route.params.id },
              }"
              class="card-header-icon has-text-black"
            >
              <b-icon size="is-small" icon="plus" />
            </router-link>
          </header>
          <div class="content card-content">
            <div class="columns is-mobile">
              <div class="column">
                <b-field class="pengampu-field" grouped group-multiline>
                  <div
                    v-for="row in stase.stasepengampu_set"
                    :key="row.id"
                    class="tag-control"
                  >
                    <b-tag
                      :closable="canChange"
                      class="is-grey-res"
                      @close="confirmDeletePengampu(row)"
                      >{{ row.preceptor }}</b-tag
                    >
                  </div>
                </b-field>
                <div
                  v-if="stase.stasepengampu_set.length == 0"
                  class="content has-text-grey has-text-centered"
                >
                  <p>Belum ada dosen pengampu yang terdaftar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Mahasiswa</p>
            <router-link
              v-if="canAddPSPD"
              :to="{ name: 'stase-mahasiswa-add', params: { id: stase.id } }"
              class="card-header-icon has-text-black"
            >
              <b-icon size="is-small" icon="plus" />
            </router-link>
          </header>
          <div class="content card-content">
            <div class="columns is-mobile">
              <div class="column">
                <PpdhTable v-if="isLoaded" :stase="stase"></PpdhTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Stase from "../models/stase.js";
import { mapGetters } from "vuex";

export default {
  name: "StaseDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.staseMdl = new Stase();
    this.objectMdl = this.staseMdl;
    return this.staseMdl.getObservables();
  },
  components: {
    PpdhTable: () => import("@/apps/stase/components/PpdhTable.vue"),
  },
  computed: {
    canDelete() {
      return this.isLoaded && this.stase.extra_data.can_change; // &&
      // this.stase.stasepspd_set.length > 1
    },
    canAddPSPD() {
      return (
        this.isLoaded &&
        this.isAdmin &&
        this.stase.extra_data.can_add_pspd &&
        this.stase.stasepengampu_set.length > 0
      );
    },
    canChange() {
      return this.isLoaded && this.isAdmin && this.stase.extra_data.can_change;
    },
    ...mapGetters("accounts", ["isPSPD", "isAdmin"]),
  },
  methods: {
    fetchData() {
      this.staseMdl.load(this.$route.params.id);
    },
    confirmDeletePengampu(row) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> pengampu dari rotasi ini?",
        confirmText: "Hapus Pengampu",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          const pengampuUrl = `pengampu/${row.id}`;
          // const params = {periode_pk: this.jadwal.id};
          this.staseMdl.delete(pengampuUrl, () => {
            const idx = this.stase.stasepengampu_set.indexOf(row);
            this.stase.stasepengampu_set.splice(idx, 1);
          });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .card-content {
    font-size: 0.9rem !important;
    padding: 0.5rem;
  }

  .columns {
    margin-right: unset !important;
  }
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.icon {
  vertical-align: middle;
}

.pengampu-container {
  margin-top: 0.5rem;
}

.pengampu-field {
  margin-bottom: unset !important;
}

div.table-card-content {
  overflow-x: auto;
  padding: 0 !important;
}

.is-grey-res {
  color: white !important;
  background-color: #6d6d6d !important;
}

.del-btn {
  color: #00d1b2 !important;
}
</style>
